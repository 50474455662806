<template>
  <div class="note-editor">
    <h1>{{ isEditing ? "Edit Note" : "Create Note" }}</h1>
    <textarea v-model="noteContent" placeholder="Write your note here..."></textarea>
    <button @click="saveNote">Save</button>
  </div>
</template>

<script>
export default {
  name: "ZettelkastenNote",
  props: {
    // Optional prop; if provided, we're editing an existing note.
    noteId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      noteContent: "",
      isEditing: false,
    };
  },
  created() {
    if (this.noteId) {
      this.isEditing = true;
      this.fetchNote();
    }
  },
  methods: {
    fetchNote() {
      // Simulate fetching note data.
      // In a real app, you might use axios or fetch to get data from an API:
      // axios.get(`/api/notes/${this.noteId}`).then(response => { this.noteContent = response.data.content })
      this.noteContent = "This is the existing note content loaded for editing.";
    },
    saveNote() {
      if (this.isEditing) {
        // Update note logic.
        console.log("Updating note:", this.noteContent);
        // e.g., axios.put(`/api/notes/${this.noteId}`, { content: this.noteContent });
      } else {
        // Create note logic.
        console.log("Creating note:", this.noteContent);
        // e.g., axios.post('/api/notes', { content: this.noteContent });
      }
      // Optionally, navigate away or give user feedback after saving.
    },
  },
};
</script>

<style scoped>
.note-editor {
  max-width: 600px;
  margin: 2rem auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  font-family: sans-serif;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fafafa;
}

.note-editor h1 {
  text-align: center;
  margin-bottom: 1rem;
}

.note-editor textarea {
  width: 100%;
  height: 300px;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  margin-bottom: 1rem;
}

.note-editor button {
  align-self: flex-end;
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  background-color: #1d4ed8;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.note-editor button:hover {
  background-color: #2563eb;
}
</style>
