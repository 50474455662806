export function drawCell(ctx, centerX, centerY, radiusX, radiusY, noiseAmplitude = 10, steps = 100) {
      ctx.beginPath();
      // A random phase adds variation to the sine modulation.
      const phase = Math.random() * Math.PI * 2;
      for (let i = 0; i <= steps; i++) {
        // Calculate the angle (from 0 to 2π)
        const angle = (i / steps) * 2 * Math.PI;
        // Compute an offset that oscillates along the edge.
        // The multiplier (here, 5) controls the number of waves around the cell.
        const offset = noiseAmplitude * Math.sin(5 * angle + phase);
        // Apply the offset to both radii to keep the shape roughly oval.
        const x = centerX + (radiusX + offset) * Math.cos(angle);
        const y = centerY + (radiusY + offset) * Math.sin(angle);
        if (i === 0) {
          ctx.moveTo(x, y);
        } else {
          ctx.lineTo(x, y);
        }
      }
      ctx.closePath();
      // Fill and stroke styles can be adjusted to your taste.
      ctx.fillStyle = "#E0FFE0";  // A light green fill
      ctx.fill();
      ctx.strokeStyle = "#009900";  // A darker green outline
      ctx.stroke();
    }
