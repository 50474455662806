<template>
  <div class="w-full">
    <h1 class="font-bold text-2xl mb-6 text-blue-600 border-b pb-2">New Card</h1>
     <!-- Save Button -->
    <div class=" mt-6">
      <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" @click="save">Save</button>
      <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" @click="save_new">Save (Create New)</button>
      <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" @click="cloze">Cloze</button>     
    </div>

     

     

    <div class="flex space-x-6">
      <!-- Text Areas (Left Side) -->
      <div class="w-1/2">

	<input 
        type="text" 
        v-model="tag" 
        class="border rounded p-2 w-full" 
        placeholder="Enter Tag"
    />	
        <h2 class="text-2xl mb-4">Front:</h2>
	
        <textarea class="w-full border rounded mb-4" v-model="newFront" rows="6" placeholder="Enter front of card here..."></textarea>

        <h2 class="text-2xl mb-4">Back:</h2>
        <textarea class="w-full border rounded" v-model="newBack" rows="6" placeholder="Back of card, first line will be converted to audio..."></textarea>
      </div>

      <!-- Drawing Components (Right Side) -->
      <div class="w-1/2">
        <!-- Drawing Controls -->
    <h2 class="text-2xl mb-4">Draw:</h2>
   
    <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" v-bind:disabled="genDisabled" @click="gen">Gen</button>
         <pulse-loader :loading="isLoading"  :color="colorl" :size="size"></pulse-loader>
     <input 
        type="text" 
        v-model="style" 
        class="border rounded p-2 w-full" 
        placeholder="Enter Style"
       />
      <input type="file" @change="handleImageUpload" />
        <div class="flex items-center space-x-4 mb-4">
          <!-- Color Picker -->
          <Compact @color-changed="updateColor" v-model="colors" class="flex-shrink-0"></Compact>

          <!-- Draw Mode -->
          <div class="flex flex-col items-start">
            <select v-model="selectedMode" @change="onModeChange" class="border rounded px-2 py-1">
              <option value="draw">Draw</option>
              <option value="fill">Fill</option>
              <option value="erase">Erase</option>
            </select>
          </div>
	  <button class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" @click="clear">Clear</button>

          <!-- Pen Size -->
          <div class="flex flex-col items-center space-y-2">
            <div class="w-10 h-10 border rounded-full flex items-center justify-center">
              <div :style="{ width: penSize + 'px', height: penSize + 'px' }" class="bg-black rounded-full"></div>
            </div>
            <input type="range" v-model="penSize" min="1" max="38" class="w-24" @input="onPenSizeChange">
          </div>
        </div>

        <!-- Canvas -->
        <div class="flex justify-center items-center border p-2 rounded h-[512px] w-full">
          <canvas ref="sketchpad" width="500" height="500"></canvas>
	 
        </div>
      </div>
    </div>

   
  </div>
</template>



<script>
//todo move the draw app into is own component
//https://github.com/thiswillbeyourgithub/AnkiAIUtils
import axios from 'axios';
import { get_token,backend_post, backend_upload } from '@/services/cognito-auth';
import * as zeit from '@/services/zeit.js';
import {daysTillNext} from '@/services/card-helper.js';
import { server } from '@/constants.js';
import { Atrament } from 'atrament';
import Compact from './ColorPicker.vue'
import * as fsrsJs from 'fsrs.js'

let fsrs = new fsrsJs.FSRS();


//Vue.component(Verte.name, Verte);


async function  new_card(front,back,canvas,tag) {
    console.log("Create New Card")

    
    canvas.toBlob(async function(blob) {

	const formData = new FormData();
	
	let newFront = {ops: [{insert:front}]}
	let newBack = {ops: [{insert:back}]}
	let key =  Date.now()
	let new_card = { }

	let fsrs_card = new fsrsJs.Card();
	
	new_card.jsondata = {}
	new_card.scores = []
	new_card.tag = tag

	new_card.jsondata.fsrs_card = fsrs_card	
	//TODO: use fsrs to create card
    
	console.log(`newFront ${newFront} newback ${newBack}`)
	formData.append('file', blob, key);

	//upload image


	new_card.due_date =   zeit.get_date_string(zeit.toLocalTime(zeit.todays_date()))

	new_card.jsondata.front = newFront
	new_card.jsondata.back = newBack
	
	new_card.jsondata.key = key


	new_card.jsondata = JSON.stringify(new_card.jsondata)

	let r = await backend_post(`${server}/cards/add`,new_card)
	console.log(r)

	let ur = await backend_upload(`${server}/upload/card/${r.data.id}`,formData)
	console.log(ur.data.path)
	//new_card.jsondata.path = ur.data.path

	
  /*

		

		
    
		console.log("NC:",new_card)
		backend.backend_post(`${server}/cards/add`,new_card, (r) => {

		    console.log("Card Added")
		    f()

  })
  */
     })
}

function createClozeSentences(backText) {
  // Find the cloze indicator and extract the word
  const clozeMatch = backText.match(/<!\b(.*?)\b>/);
    const clozeWord = clozeMatch ? clozeMatch[1] : "";

  let nc = clozeWord[0] +  "_".repeat(clozeWord.length-1)

    
  // Create the cloze sentence with underscores
    let clozeSentence = backText.replace(/<!\b.*?\b>/, nc)//"_".repeat(clozeWord.length));
    clozeSentence = clozeSentence.split('\n')[0]

  // Create the reveal sentence without the indicator
  const revealSentence = backText.replace(/<!\b.*?\b>/, clozeWord);

  return [clozeSentence, revealSentence];
}



export default {
  name: 'CardNew',
  data() {
      return {
	  newFront: "",
	  newBack: "",
	  color: {hex: '#ff0000'},
	  sketchpad: null,
	  selectedMode: 'draw',
	  penSize: 10, // Initial pen size
	  	  tag: ""
      // Your data properties go here
    };
  },
components: {

    	Compact,
	},
  props: {
    // Your props go here
  },
  computed: {
    // Your computed properties go here
  },
    methods: {
	gen() {
	    console.log("GEN")
	    },
	cloze() {
	    console.log("Cloze")
	    console.log(this.newBack.split('\n'))
	    //let back = this.newBack.split('\n')[0]

	    const [clozeSentence, revealSentence] = createClozeSentences(this.newBack)
		console.log(clozeSentence); // Output: Ich verstehe Ihre _________
console.log(revealSentence); // Output: Ich verstehe Ihre Betrübnis							 

	    this.newFront = clozeSentence
	this.newBack = revealSentence
	    
	    },
	 updateColor(newColor) {

	     this.sketchpad.color = newColor.hex
	 },
	onModeChange() {
      // Callback function when the mode is changed
      console.log("Selected Mode:", this.selectedMode);
	    // You can add more logic here or emit this to a parent component if needed
	     this.sketchpad.mode = this.selectedMode
	    //sketchpad.weight = parseFloat(event.target.value);
	},
	onPenSizeChange(event) {
	    console.log("Pen size changed to:", this.penSize);

	    let ps = this.penSize;
	    this.sketchpad.weight = parseFloat(ps)
	},
	clear() {
	    console.log("CLEAR")
	    this.sketchpad.clear();
	},
	async save() {
	    console.log("New card")
	    console.log(this.newFront,this.newBack)
	    const canvas = this.$refs.sketchpad;	 
	    await new_card(this.newFront,this.newBack,canvas,this.tag)
	    this.$router.push(`/cards`)
	    
	},
	async save_new() {
	    console.log("New card")
	    console.log(this.newFront,this.newBack)
	    const canvas = this.$refs.sketchpad;	 
	    await new_card(this.newFront,this.newBack,canvas)
	    this.$router.push(`/cards/new`)
	    
	},
	
	handleImageUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const img = new Image();
          img.onload = () => {
            // Resize the canvas to fit the image
            //this.$refs.sketchpad.width = img.width;
              //this.$refs.sketchpad.height = img.height;
	      const canvas = this.$refs.sketchpad;
	      let ctx  = canvas.getContext('2d');

	      const hRatio = canvas.width / img.width;
        const vRatio = canvas.height / img.height;
        const ratio = Math.min(hRatio, vRatio);

        // Calculate the scaled dimensions
        const centerShift_x = (canvas.width - img.width * ratio) / 2;
        const centerShift_y = (canvas.height - img.height * ratio) / 2;
        // Draw the scaled image on the canvas
              ctx.clearRect(0, 0, canvas.width, canvas.height);
              ctx.drawImage(img, 0, 0, img.width, img.height, centerShift_x, centerShift_y, img.width * ratio, img.height * ratio);
	      
	      
            //ctx.drawImage(img, 0, 0);
          };
          img.src = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    }

  },
    mounted() {
	          const canvas = this.$refs.sketchpad;
	this.sketchpad = new Atrament(canvas, {	width: 500,
						height: 500});
    // Code to run when the component is mounted
  },
};
</script>

<style scoped>
/* Your CSS goes here */
</style>
