    <template>
      <div ref="focusDiv" tabindex="0" class="flashCardContainer  pt-6"   @keyup="keyHandler" >
	<h1  class="font-bold text-2xl mb-6 text-blue-600 border-b pb-2">Cards: {{ cardCount }}</h1>

	 <div class="space-x-2">
    <button class="bg-blue-500 hover:bg-blue-600 active:bg-blue-700 text-white font-bold py-2 px-4 rounded" @click="showSide">Answer (Spc)</button>
    <button class="bg-green-500 hover:bg-green-600 active:bg-green-700 text-white font-bold py-2 px-4 rounded" @click="easy">Easy (1)</button>
    <button class="bg-yellow-500 hover:bg-yellow-600 active:bg-yellow-700 text-white font-bold py-2 px-4 rounded">Ok (2)</button>
    <button class="bg-orange-500 hover:bg-orange-600 active:bg-orange-700 text-white font-bold py-2 px-4 rounded">Hard (3)</button>
    <button class="bg-red-500 hover:bg-red-600 active:bg-red-700 text-white font-bold py-2 px-4 rounded">Again (4)</button>
    <button class="bg-indigo-500 hover:bg-indigo-600 active:bg-indigo-700 text-white font-bold py-2 px-4 rounded">Play (5)</button>
    <button class="bg-purple-500 hover:bg-purple-600 active:bg-purple-700 text-white font-bold py-2 px-4 rounded" @click="edit">{{ buttonText }}</button>
    <button class="bg-gray-500 hover:bg-gray-600 active:bg-gray-700 text-white font-bold py-2 px-4 rounded" @click="hide">Hide (h)</button>
</div>

	<div class="flashcard h-4/5" @click="showSide"  tabindex="0">
    
    <!-- max-h-full -->

    <div class=" rounded overflow-hidden shadow-lg ">
              <div class="flex justify-center items-center border p-2 rounded h-[512px] w-full">
          <canvas ref="sketchpad" width="500" height="500"></canvas>
	 
              </div>
	      <!--
	    <div class="flex justify-center ">
	      <img class="scale-100" v-if="imageUrl" :src="imageUrl" alt="Flash">
	    </div>
	    -->
	    <div class="px-6 py-4">
	      <div v-if="!showState">
    
		<div class="font-bold text-2xl mb-2" v-html="formattedText"></div>
	      </div>
	      <div v-else-if="showState == true">
		<div class="font-bold text-2xl mb-2" v-html="formattedBackText" ></div>
	      </div>
	      <p class="text-gray-700 text-base">

	      </p>
	    </div>
	    
	  </div>

    
	</div>
	
    




 
  
    </div>
     <div>
    <!-- Audio element -->
    <audio ref="audioPlayer" :src="audioUrl"></audio>


  </div>

</template>

<script>
import axios from 'axios';
import { get_token,backend_post,backend_upload } from '@/services/cognito-auth';
import * as zeit from '@/services/zeit.js';
import * as audio from '@/services/audio-helper.js';
import * as dermotor from '@/services/dermotor.js';

import { server } from '@/constants.js';

import { Atrament } from 'atrament';
import { Chrome } from 'vue-color';

import * as fsrsJs from 'fsrs.js'



async function get_card(config) {
    //let due_date = encodeURIComponent(zeit.get_date_string(zeit.toLocalTime(zeit.todays_date())))

    

    let d = new Date()
    let due_date = zeit.get_date_string(d)

    console.log("DD:",due_date)
    const response = await axios.get(`${server}/cards/due/one?date=${due_date}`,config)
    
    return response.data
    
 
}

		 

export function toPlaintext(delta) {
    
    return delta.reduce(function (text, op) {
	if (!op.insert) return ''//throw new TypeError('only `insert` operations can be transformed!');
	if (typeof op.insert !== 'string') return text + ' ';
	return text + op.insert;
    }, '');
};

function daysTillNext( x, a = 6.0, b = -0.8, c = 0.28, d = 0.02, theta = 0.2) {
    if (x[x.length - 1] < 4) {
	return 1
    }
    
    const history = x
    
    // Calculate latest correctness streak
    let streak = 0
    for (let i = 0; i < history.length; i++) {
	if (history[i] > 3) {
	    streak++
	} else {
	    break
	}
    }
    
    // Sum up the history
    const historySum = history.reduce(
	(prev, val) => prev + (b + (c * val) + (d * val * val)),
	0
    )
    
    return a * Math.pow(Math.max(1.3, 2.5 + historySum), theta * streak)
}

async function  mark_card(level,cur_card) {
    console.log("mark Card",level)
    
    if (!'scores' in cur_card.jsondata)
	cur_card.jsondata.scores = []
    
    let key_map = {1:5,2:4,3:3,4:0}


    let fsrs_key_map = {1:rating.Easy,2:rating.Good,3:rating.Hard,4:rating.Again}

    
    let card = new fsrsJs.Card();
    let scheduling_cards = null
    let fsrs_card = null

    console.log(card)
    if ('fsrs_card' in cur_card.jsondata) {

	card = cur_card.jsondata.fsrs_card
	card.due = new Date(card.due)
	let dd = new Date(card.last_review)
	console.log(dd)
	card.last_review = new Date(card.last_review)

	scheduling_cards = fsrs.repeat(card, card.due)
	

    }
    else {

	let now = new Date()
	scheduling_cards = fsrs.repeat(card, now)
	
	
//    = fsrs.repeat(card, card.last_review);
  

    }
    console.log("FSRS:",scheduling_cards);
    console.log(fsrs_key_map[level])

    fsrs_card = scheduling_cards[fsrs_key_map[level]].card
    console.log(fsrs_card)

    console.log("FSRS DUE DATE:",zeit.get_date_string(fsrs_card.due))
    
    //5 - easy - 1
    //4 - good - 2 
    //3 - ok - 3
    //0 - hard - 4
    //
    
    let scores = []
    
    if ('scores' in cur_card.jsondata)
	scores = [...cur_card.jsondata.scores]
    
    scores.push(key_map[level])

    cur_card.jsondata.fsrs_card = fsrs_card


    let d = new Date()
    cur_card.due_date = zeit.get_date_string(fsrs_card.due)//zeit.get_date_string(zeit.plus_days(d,daysTillNext(cur_card.jsondata.scores)))
    console.log("NEXT DD:",cur_card.due_date)
    cur_card.jsondata =  JSON.stringify(cur_card.jsondata)
    console.log(cur_card)
    
    
    let r = await backend_post(`${server}/cards/mark/${cur_card.id}`,cur_card)
    /*
      auth.backend_post(`${l_server}/cards/edit/${cur_card.id}`,cur_card, (r) => {
      console.log("Card Edited")
      //check review to go back to start or reveiwing 
      })
      
      //move to next card
      
      */
    
}

let fsrs = new fsrsJs.FSRS();
//let card = new fsrsJs.Card();
let rating= fsrsJs.Rating;
let state = fsrsJs.State;

export default {
    name: 'FlashCard',
    data() {
	return {
	    front: 'Question',
	    back: 'Answer',
	    showBack: false,
	    showState: 0,
	    buttonText: 'Edit (e)',
	    id: null,
	    card: null,
	    imageUrl: null,
	    sketchpad: null,
	    cardCount: 0,
	    newFront: "",
	    newBack: "",
	    audioUrl: '',
	    color: {hex: '#ff0000'},
	    showStatus: 'Show'
	};
    },
    components: {
	'chrome-picker': Chrome
    },
    computed: {
	formattedText() {
	    return this.front.replace(/\n/g, '<br>');
	},
	formattedBackText() {
	    return this.back.replace(/\n/g, '<br>');
	},
    },
    watch: {
	showState(newVal) {
	    if (newVal === 2) {
          this.$nextTick(() => {

          const canvas = this.$refs.sketchpad;
	      this.sketchpad = new Atrament(canvas);
          // now you can use the sketchpad object to draw on the canvas
        });
      }
    }
	      },
      methods: {
	 updateColor(newColor) {
      this.color = newColor;
	 },
	  onPress(e)  {
	      console.log(e)
	  },
	  showSide() {

	      if (this.showState != 2)
		  this.showState = !this.showState
	  },
	  async hide() {

	      let r = await backend_post(`${server}/cards/hide/${this.card.id}`,{})
	      console.log("R:",r)
	      this.load_next_card()
	      //`${l_server}/cards/hide/${cards_g[0].id}`,{},() => {})
	  },
	  edit() {
	      console.log("EDIT")
	     

	      this.$router.push(`/cards/edit/${this.card.id}`)
	      
	      	  //const canvas = this.$refs.sketchpad;
//	  console.log("CANVAS:",canvas)

	  //const sketchpad = new Atrament(canvas);



	  },
	 
	  keyHandler(e) {
	      console.log(e)
	    if (e.code == 'Space') {
		console.log("Submit:",e)
		this.showSide()
	    }
	      else if (e.code == 'Digit1')
		  this.easy()
	      else if (e.code == 'Digit2')
		  this.good()
	      else if (e.code == 'Digit3')
		  this.ok()
	       else if (e.code == 'Digit4')
		   this.hard()
	       else if (e.code == 'Digit5')
		   this.play()
	      else if (e.code == 'KeyE')
		  this.edit()
	      else if (e.code == 'KeyH'){
		  console.log("HIDE")
		   this.hide()
		   }

	      //set focus back to main window
	      // this.$refs.myButton.blur();
	      this.$refs.focusDiv.focus();

	},	  
	  async load_next_card() {
	      this.audioUrl = null
	      
	      const config = {
		  headers:{
    		      "Authorization": await get_token(),
		      
		  }
	      };
	      let card = await get_card(config)

	      this.showState = false
	      this.imageUrl = null
	      if (!card.card) {
		  this.front = "Done"
		  this.cardCount = 0

  const canvas = this.$refs.sketchpad;
	      var ctx = canvas.getContext("2d");
	      dermotor.clear_rect(ctx)		  
		  return
	      }
		  
	      console.log(card.card.jsondata)

	      this.front = toPlaintext(card.card.jsondata.front.ops)
	      //	   let back = toPlaintext(card.card.jsondata.back.ops)

	      this.back = toPlaintext(card.card.jsondata.back.ops)// back.replace(/\n/g, '<br>');
	      this.id = card.card.id
	      this.card = {...card.card}
	      console.log(this.card)

	      const canvas = this.$refs.sketchpad;
	      var ctx = canvas.getContext("2d");
	      dermotor.clear_rect(ctx)

	      dermotor.load_image(ctx,canvas,card.card.jsondata.url)
/*
	      if ('url' in card.card.jsondata)
		  this.imageUrl = card.card.jsondata.url
*/

	      //
	      let d = new Date()
	      let due_date = zeit.get_date_string(d)

	      let url = `${server}/cards/due/count?date=${due_date}`
	      let response = await axios.get(url,config)

	      this.cardCount = response.data


	      let audioDemo = document.getElementById(`myaudio`);

	      let audio_link  = await axios.get(`${server}/cards/audio/${this.card.id}`,config)

	      if (audio_link.data.url) {

		  this.audioUrl = audio_link.data.url

}
	      //audioDemo.src = audio_link.url

	      
	      
	  },
	  show() {
	      
	      },
      async easy() {
	  console.log("Easy")
	  await mark_card(1,this.card)
	  this.load_next_card()
      
      },
	    async good() {
	  console.log("Ok")
	  await mark_card(2,this.card)
	  this.load_next_card()
      
      },
	    async ok() {
	  console.log("Ok")
	  await mark_card(3,this.card)
	  this.load_next_card()
      
      },
	  async hard() {
	  console.log("Hard")
	  await mark_card(4,this.card)
	  this.load_next_card()
	      
	  },
	  play() {
	      console.log("Play")

	      this.$refs.audioPlayer.play();


	  },
	  claer() {
	      console.log("Clear Canvas")
	  }
      },
      async mounted() {
	  this.$refs.focusDiv.focus();
	console.log("Load Card")

	  this.load_next_card()
	}
};
</script>

<style scoped>
.flashcard {
    /*  border: 1px solid #000;
      padding: 20px;
  margin: 20px;

   */
 
  /*  width: 200px;*/
  /*
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
 */

    .focusDiv:focus {
    outline: none;
}
}

.flashCardContainer:focus {
  outline: none;
}
</style>
