import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router';
import App from './App.vue'
//import HomePage from './components/HomePage.vue';
import HomePage from './components/NewHome.vue';
import LoginPage from './components/LoginView.vue';
import ResetPassword from './components/ResetPassword.vue';
import ForgotPassword from './components/ForgotPassword.vue';
import Register from './components/Register.vue';
import Signout from './components/Signout.vue'
import MainWindow from './components/MainWindow.vue';
import CardsMainView from './components/CardsMainView.vue';
import AssistMain from './components/AssistMain.vue';
import AssistChat from './components/AssistChat.vue';
import AssistRecord from './components/AssistRecord.vue';
import AssistEdit from './components/AssistEdit.vue';
import AssistReview from './components/AssistReview.vue';
import AssistPreview from './components/AssistPreview.vue';
import AssistTopic from './components/AssistTopic.vue';
import AssistTopics from './components/AssistTopics.vue';
import ActionMain from './components/ActionMain.vue';
import TSMain from './components/TSMain.vue';
import TSNew from './components/TSNew.vue';
import TSList from './components/TSList.vue';
import TSEdit from './components/TSEdit.vue';
import CardNew from './components/CardNew.vue';
import CardsView from './components/CardsView.vue';
import CardsStats from './components/CardsStats.vue';
import QuizMain from  './components/QuizMain.vue';
import QuizTake from './components/QuizTake.vue';
import QuizNew from './components/QuizNew.vue';
import QuizImport from './components/QuizImport.vue';
import QuizEdit from './components/QuizEdit.vue';
import QuizList from './components/QuizList.vue';
import CardsEditView from './components/CardEdit.vue';
import CardsHelp from './components/CardsHelp.vue';
import CardsImport from './components/CardsImport.vue';
import CardsList from './components/CardsList.vue';
import ResearchProject from './components/ResearchProject.vue'
import ProjectNew from './components/ProjectNew.vue'
import ProjectSettings from './components/ProjectSettings.vue'
import DocGen from './components/DocGen.vue'
import TaskMain from './components/TaskMain.vue';
import Tasks from './components/Tasks.vue'
import store from './store';

import WerkBankMain from './components/WerkBankMain.vue';
import WerkBankEdit from './components/WerkBankEdit.vue';
import WerkBankList from './components/WerkBankList.vue';

import MoodMain from './components/MoodMain.vue';
import MoodEdit from './components/MoodEdit.vue';
import MoodNew from './components/MoodNew.vue';
import MoodList from './components/MoodList.vue';

import ZKMain from './components/ZKMain.vue';
import ZKEdit from './components/ZKEdit.vue';

import './assets/tailwind.css'

//import { isLoggedIn } from './services/cognito-auth';
import * as auth from './services/cognito-auth';

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faCircleCheck,faCircleXmark } from '@fortawesome/free-solid-svg-icons'


library.add(faCircleCheck)
library.add(faCircleXmark)


const routes = [
    { path: '/', component: HomePage },
    { path: '/login', component: LoginPage },
    { path: '/reset-password', component: ResetPassword },
    { path: '/forgot-password/:email/:code', component: ForgotPassword, props: true},
    { path: '/register', component: Register },
    { path: '/signout', component: Signout },
    { path: '/', component: MainWindow, meta: { requiresAuth: true } },
    { path: '/chat', component: MainWindow, meta: { requiresAuth: true } },
    { path: '/cards', component: CardsMainView, meta: { requiresAuth: true },
      children: [
	  {path: '', component: CardsView},
	  { path: 'new', component: CardNew, meta: { requiresAuth: true } },
	  { path: 'edit/:cardId', props:true, name: 'CardEdit', component: CardsEditView, meta: { requiresAuth: true } },
	  { path: 'help', component: CardsHelp, meta: { requiresAuth: true } },
	  { path: 'stats', component: CardsStats, meta: { requiresAuth: true } },
	  { path: 'import', component: CardsImport, meta: { requiresAuth: true } },
	  { path: 'view', component: CardsList, meta: { requiresAuth: true } },
	   
      ]},
    { path: '/quiz', component: QuizMain, meta: { requiresAuth: true },
      children: [
	  { path: 'take/:quizId',  props:true, component: QuizTake, meta: { requiresAuth: true }},
	  { path: 'list', component: QuizList, meta: { requiresAuth: true }},
	  { path: 'new', component: QuizNew, meta: { requiresAuth: true }},
	  { path: 'import', component: QuizImport, meta: { requiresAuth: true }},
	  { path: 'edit/:quizId', props:true, name: 'QuizEdit', component: QuizEdit, meta: { requiresAuth: true } },	  
      ]},
    { path: '/assist', component: AssistMain, meta: { requiresAuth: true },
      children: [
	  { path: 'record', component: AssistRecord, meta: { requiresAuth: true }},
	  { path: 'review', component: AssistReview, meta: { requiresAuth: true }},
	  { path: 'edit/:noteId',  props:true, component: AssistEdit, meta: { requiresAuth: true }},
	  { path: 'preview/:noteId',  props:true, component: AssistPreview, meta: { requiresAuth: true }},
	  { path: 'topic/:topicId',  props:true, component: AssistTopic, meta: { requiresAuth: true }},
	  { path: 'topics', component: AssistTopics, meta: { requiresAuth: true }},
      ]},
    { path: '/action', component: ActionMain, meta: { requiresAuth: true },
      children: []
    },
    { path: '/werkbank', component: WerkBankMain, meta: { requiresAuth: true },
      children: [
	  { path: 'new', component: WerkBankEdit, meta: { requiresAuth: true }},
	  { path: 'list', component: WerkBankList, meta: { requiresAuth: true }},
	  { path: 'edit/:wbId',  props:true, component: WerkBankEdit, meta: { requiresAuth: true }},
      ]},
    { path: '/mood', component: MoodMain, meta: { requiresAuth: true },
      children: [
	  { path: 'new', component: MoodNew, meta: { requiresAuth: true }},
	  { path: 'list', component: MoodList, meta: { requiresAuth: true }},
	  { path: 'edit/:wbId',  props:true, component: MoodEdit, meta: { requiresAuth: true }},
      ]},
    { path: '/zk', component: ZKMain, meta: { requiresAuth: true },
      children: [
	  { path: 'new', component: ZKEdit, meta: { requiresAuth: true }},
	  { path: 'list', component: TSList, meta: { requiresAuth: true }},
	  { path: 'edit/:tsId',  props:true, component: TSEdit, meta: { requiresAuth: true }},
      ]},   
    
    { path: '/ts', component: TSMain, meta: { requiresAuth: true },
      children: [
	  { path: 'new', component: TSNew, meta: { requiresAuth: true }},
	  { path: 'list', component: TSList, meta: { requiresAuth: true }},
	  { path: 'edit/:tsId',  props:true, component: TSEdit, meta: { requiresAuth: true }},
      ]},   
    { path: '/project/new', component: ProjectNew, meta: { requiresAuth: true } },
    { path: '/project/settings/:projectId', name: 'ProjectSettings', props:true, component: ProjectSettings, meta: { requiresAuth: true } },
    { path: '/doc/gen/:projectId', name: 'DocGen', props:true, component: DocGen, meta: { requiresAuth: true } },
    { path: '/research/:projectId', name: 'ResearchProject', component: ResearchProject, props: true, meta: { requiresAuth: true }},
     { path: '/tasks', component: TaskMain, meta: { requiresAuth: true },
       children: [
      ]},

  // Add more routes as needed
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});


auth.auth_init()



router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth) && !store.state.isLoggedIn) {
    next({ path: '/login' });
  } else {
    next();
  }
});

if (auth.current_user()) {
  store.commit('logIn');
router.push('/cards');
 store.dispatch('fetchFeatureFlags').then(() => {
  
    
	});

}
    
/*
// Check if the user is already logged in
if (isLoggedIn()) {
  store.commit('logIn');
}
*/

createApp(App)
  .use(store)
    .use(router)
    .component('font-awesome-icon', FontAwesomeIcon)
  .mount('#app');





