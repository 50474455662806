<template>
  <div class="container mx-auto p-6">

    <!-- Search Bar -->
    <div class="mb-4 flex">
      <input type="text" v-model="searchQuery" placeholder="Search..." class="p-2 border border-gray-300 rounded-l w-full">
      <button @click="handleSearch" class="p-2 bg-blue-500 text-white hover:bg-blue-600 rounded-r">Search</button>
    </div>

   
    
  <table class="min-w-full bg-white border border-gray-300">
    <thead>
        <tr>
            <th class="py-2 px-4 border-b border-gray-300 text-left text-sm font-semibold text-gray-600 uppercase tracking-wider" v-for="header in headers" :key="header">{{ header }}</th>
        </tr>
    </thead>
    <tbody>
        <tr v-for="quiz in quizzes" :key="quiz.id">
            <td class="py-2 px-4 border-b border-gray-300">
                <router-link class="text-gray-700 hover:text-blue-600 transition-colors" :active-class="activeLinkClass" :to="`/werkbank/edit/${quiz.id}`">{{ quiz.name }}</router-link>
            </td>


            <!-- Add more columns as needed -->
            <td class="py-2 px-4 border-b border-gray-300">
              <div class="flex space-x-2"> <!-- Flex container to align buttons horizontally with spacing -->
		<!--
		 <button @click="takeQuiz(quiz.id)" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded">
                       Edit
                    </button>
                    
                   -->
                </div>
            </td>
        </tr>
    </tbody>
   

</table>

 <div class="mt-4 flex justify-center">
    <button @click="prevPage()" :disabled="currentPage <= 0" class="px-4 py-2 bg-gray-200 hover:bg-gray-300 rounded-l">Previous</button>
  <span class="px-4 py-2 bg-gray-200">{{ currentPage + 1 }} / {{ totalPages }}</span>
    <button @click="nextPage()"  :disabled="currentPage >= totalPages" class="px-4 py-2 bg-gray-200 hover:bg-gray-300 rounded-r">Next</button>
    </div>

  </div>
</template>

<script>
import { server } from '@/constants.js';
import { backend_get,backend_post,backend_delete } from '@/services/cognito-auth';
export default {
 
    data() {
return {

	    headers: ['Name', 'Due Date'],
	    quizzes: [],
	    currentPage: 0,
	    itemsPerPage: 10,
	    totalPages: 100,
	    activeLinkClass: "font-bold border-l-4 border-blue-600 pl-2"
	};
    },
   
    methods: {
	takeQuiz(id) {
	    console.log("TAKE")
	    this.$router.push(`/werkbank/edit/${id}`)
	    }
	
    },
     async mounted() {
	console.log("Mounted")

	 
	 let r =  await backend_get(`${server}/werkbank`)
	 console.log(r)

	 r.data.forEach((quiz) => {
		  console.log("Quiz:",quiz)

		  this.quizzes.push({ id: quiz.id, name:quiz.code.name})
	     })



    },

}

</script>

<style scoped>
/* You can add any additional styles specific to this component here */
</style>

