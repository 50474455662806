//https://www.colorhexa.com/808080

//https://leanrada.com/notes/sweep-and-prune/


export function set_size_to_window(ctx) {

    ctx.canvas.width  = window.innerWidth;
    ctx.canvas.height = window.innerHeight;
}    

export function set_canvas_size_to_parent(canvas) {

    canvas.setAttribute('width', canvas.parentNode.offsetWidth);
    canvas.setAttribute('height', canvas.parentNode.offsetHeight);
}

export function outside_x(canvas,x) {

//    const rect = canvas.getBoundingClientRect();
    const { width, height } = canvas.getBoundingClientRect();
    console.log(width)
    if (x > width)
	return true

    return false
}


export function draw_screen_bg(canvas,ctx) {

    const { width, height } = canvas.getBoundingClientRect();
 ctx.beginPath();
    ctx.rect(0, 0, width, height);

    
    //ctx.fillStyle = "grey";
//"rgb(255, 165, 0)";
 ctx.fillStyle  =  "rgb(51, 65, 85)";//`rgb(51,65,85, 0)`;
ctx.fill();


}



function draw_screen(ctx) {

 ctx.beginPath();
ctx.rect(20, 20, 150, 100);
ctx.fillStyle = "white";
ctx.fill();


}

export function clear_rect(ctx) {

    const { width, height } = ctx.canvas.getBoundingClientRect();
    ctx.clearRect(0, 0, width, height);
}

export function draw_rect(ctx, start_x, start_y, width, height, color) {
    ctx.beginPath();
    ctx.rect(start_x, start_y, width, height);
    ctx.fillStyle = color
    ctx.fill();
 
}

export function draw_image(ctx,path,h,w) {
    var img = new Image;
    img.onload = function() {
	ctx.drawImage(img, 0,0,h,w);

    }

    img.src = path
}


export function draw_circle(ctx,center_x,center_y,start_angle,end_angle,color,line_width = 3) {


    /*
    ctx.beginPath()
    //ctx.arc(center_x, center_y, 70, start_angle, end_angle)
    ctx.fillStyle = "white"
    ctx.arc(100, 75, 50, 0, 2 * Math.PI);
    // ctx.fill()
    ctx.stroke();
    */
    
      var radius = 5;

      ctx.beginPath();
      ctx.arc(center_x, center_y, radius, 0, 2 * Math.PI, false);
      ctx.fillStyle = color//'black';
      ctx.fill();    
      ctx.lineWidth = line_width;
      ctx.strokeStyle = color//'#000000';
      ctx.stroke();


}




function draw_line(ctx,start_x,start_y,end_x,end_y,line_width,stroke_style) {

    ctx.beginPath()
    ctx.moveTo(start_x,start_y)
    
    ctx.lineTo(end_x, end_y)

    ctx.lineWidth = line_width
//    ctx.strokeStyle = stroke_style//'#ff0000'
    
      ctx.stroke();



}

function draw_line_v(ctx,start_v,end_v,line_width,stroke_style) {
    [start_x,start_y] = start_v
    console.log(start_y)


    return draw_line(ctx,start_x,start_y,end_v[0],end_v[1],line_width,stroke_style)
}


function draw_line_a(ctx,xys,line_width, stroke_style) {
    if (xys.length < 2)
	return
    draw_line(ctx,xys[0][0],xys[0][1],xys[1][0],xys[1][1],line_width, stroke_style)
}


function draw_triangle(ctx,xys,fill_color) {

    ctx.beginPath();
ctx.moveTo(xys[0][0], xys[0][1]);
ctx.lineTo(xys[1][0], xys[1][1]);
ctx.lineTo(xys[2][0], xys[2][1]);
ctx.closePath();

// the outline
ctx.lineWidth = 5
ctx.strokeStyle = '#666666';
ctx.stroke();


    console.log("COLOR:"+ fill_color)
// the fill color
ctx.fillStyle = fill_color//"#FFCC00";
ctx.fill();

}

export function draw_text(ctx, text, x, y) {
    ctx.font = "18px sans-serif";
    //ctx.strokeStyle = "black"; // set stroke color to red
    
    //ctx.lineWidth = "1.5";  //  set stroke width to 1.5
  
  
    //ctx.strokeText(text, x, y);
    ctx.fillStyle = "black"
    ctx.fillText(text,x,y)
  

}

export function draw_text_center(ctx,text,x,y,w,h) {

    ctx.fillStyle = '#fff';
    ctx.strokeStyle = "black";
    ctx.font = '20px Arial';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText(text, x + w / 2, y + h / 2);

}
    

export function get_click_xy(ctx,event) {

    	
    const rect = ctx.canvas.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    return [x,y]
}
/*

  function loop(timestamp) {
  var progress = timestamp - lastRender

  update(progress)
  draw()

  lastRender = timestamp
  window.requestAnimationFrame(loop)
}
var lastRender = 0
window.requestAnimationFrame(loop)

*/


/*

  
 */


export function init(id) {

    const canvas = document.getElementById(id);
    const context = canvas.getContext('2d');

    //TODO: add handler functions
    return {canvas: canvas,
	    ctx:context}
}

export function gen_box(x,y,w,h) {
    return {x:x,y:y,w:w,h:h}
}

export function gen_button(x,y,w,h,txt,fn=null,tf=null) {
    return {x:x,y:y,w:w,h:h,text:txt,fn:fn,tf:tf}
}

export function gen_text_base(font,color) {
    return {font:font,color:color}
}

export function gen_text_b(font,color) {
    return {font:font,color:color}
}
			 
export function gen_text(font,color,textAlign="start",textBaseline="top") {
    return {font:font,color:color,textAlign:textAlign,textBaseLine:textBaseline}
}

export function set_font(state,text_p) {

    state.ctx.font = text_p.font 

//    state.ctx.textAlign = text_p.textAlign
//    state.ctx.textBaseline = text_p.textBaseLine

    state.ctx.fillStyle = text_p.color
}


export function set_text_props(st,textAlign,textBaseline) {

       st.ctx.textAlign = textAlign
    st.ctx.textBaseline = textBaseline

}

export function clear_rect_st(state) {

    const { width, height } = state.ctx.canvas.getBoundingClientRect();
    state.ctx.clearRect(0, 0, width, height);
}

export function clear_rect_st_box(state,box) {


    state.ctx.clearRect(box.x, box.y, box.w, box.h);
}

export function draw_screen_bg_st(st,color) {

    const { width, height } = st.canvas.getBoundingClientRect();
    st.ctx.beginPath();
    st.ctx.rect(0, 0, width, height);

    
    st.ctx.fillStyle = color//"grey";

//    ctx.fillStyle  =  "rgb(51, 65, 85)";//`rgb(51,65,85, 0)`;
    st.ctx.fill();


}

export function draw_screen_bg_st_b(st,b,color) {

    st.ctx.beginPath();
    st.ctx.rect(b.x, b.y, b.w, b.h);

    
    st.ctx.fillStyle = color

    st.ctx.fill();


}

export function set_canvas_size_st(state) {
    console.log("WTF:",state)
    state.canvas.setAttribute('width', state.canvas.parentNode.offsetWidth);
    state.canvas.setAttribute('height', state.canvas.parentNode.offsetHeight);
}



export function draw_line_st(state,start_x,start_y,end_x,end_y,line_width,stroke_style) {

    state.ctx.beginPath()
    state.ctx.moveTo(start_x,start_y)
    
    state.ctx.lineTo(end_x, end_y)

    state.ctx.lineWidth = line_width
    state.ctx.strokeStyle = stroke_style//'#ff0000'
    
      state.ctx.stroke();



}


export function draw_text_st_base(state, text, x, y) {

    state.ctx.fillText(text,x,y)
  

}

export function draw_text_st(state, text, x, y,font,color='black') {
    state.ctx.font = font //"18px sans-serif";
    //ctx.strokeStyle = "black"; // set stroke color to red
    //ctx.lineWidth = "1.5";  //  set stroke width to 1.5
  
  
    //ctx.strokeText(text, x, y);
    state.ctx.fillStyle = color

    state.ctx.fillText(text,x,y)
  

}

export function draw_text_st_p(state,text_p, text, x, y) {
    state.ctx.font = text_p.font //"18px sans-serif";
  
    state.ctx.textAlign = text_p.textAlign
    state.ctx.textBaseline = text_p.textBaseLine

    state.ctx.fillStyle = text_p.color

    state.ctx.fillText(text,x,y)
  

}



export function draw_center_text_st(state,text,tf) {
    // Clear the canvas
           const centerX = state.canvas.width / 2;
        const centerY = state.canvas.height / 2;


        // Set font properties
       state.ctx.font = tf.font
        state.ctx.textAlign = 'center';
        state.ctx.textBaseline = 'middle';
    state.ctx.fillStyle = tf.color
        // Calculate the center of the canvas
 

        // Draw the text
       state. ctx.fillText(text, centerX, centerY);
}

export function draw_center_box_text(state,text,tf,x,y,w,h) {

    state.ctx.font = tf.font
    state.ctx.fillStyle = tf.color
    state.ctx.textAlign = 'center';
    state.ctx.textBaseline = 'middle';
    state.ctx.fillText(text, x + w / 2, y + h / 2);

}


export function draw_off_center_text_st(state,text,tf,y_offset) {
    // Clear the canvas
    const centerX = state.canvas.width / 2;
    const centerY = (state.canvas.height / 2) + y_offset


        // Set font properties
       state. ctx.font = tf.font
        state.ctx.textAlign = 'center';
        state.ctx.textBaseline = 'middle';
    state.ctx.fillStyle = tf.color
        // Calculate the center of the canvas
 

        // Draw the text
       state. ctx.fillText(text, centerX, centerY);
}


// Draw the button
export function draw_button_st(state,buttonText,buttonX,buttonY,buttonWidth,buttonHeight,bg='blue') {

//    const context = canvas.getContext('2d');
//    context.clearRect(0, 0, canvas.width, canvas.height);
    state.ctx.fillStyle = bg//'#007bff';
    state.ctx.fillRect(buttonX, buttonY, buttonWidth, buttonHeight);
    
    state.ctx.fillStyle = '#fff';
    state.ctx.font = '20px Arial';
    state.ctx.textAlign = 'center';
    state.ctx.textBaseline = 'middle';
    state.ctx.fillText(buttonText, buttonX + buttonWidth / 2, buttonY + buttonHeight / 2);
}

export function draw_button_st_b(st,btn,text,font,bg='blue') {

//    const context = canvas.getContext('2d');
    //    context.clearRect(0, 0, canvas.width, canvas.height);

    st.ctx.fillStyle = bg
    st.ctx.fillRect(btn.x, btn.y, btn.w, btn.h);
//   console.log(st.ctx.textBaseline )

    st.ctx.fillStyle = '#fff';
    //st.ctx.font = font//'20px Arial';
    st.ctx.textAlign = 'center';
    st.ctx.textBaseline = 'middle';
    st.ctx.fillText(btn.text, btn.x + btn.w / 2, btn.y + btn.h / 2);

}

export function draw_buttons_st(state,buttons) {


    for (const b of buttons) {

	drawRoundedRect_st_b(state,b,20)
	draw_center_box_text(state,b.text,b.tf,b.x,b.y,b.w,b.h)
	}

}

export function draw_square_st(state, x, y, size, color) {
    state.ctx.beginPath();
    state.ctx.rect(x, y, size, size);
    state.ctx.strokeStyle = color
    state.ctx.lineWidth = 2; // Adjust the line width as needed
    state.ctx.stroke();

    return gen_box(x,y,size,size)
}

export function draw_square_st_b(state,b, color) {
    state.ctx.beginPath();
    state.ctx.rect(b.x, b.y, b.w, b.w);
    state.ctx.strokeStyle = color
    state.ctx.lineWidth = 2; // Adjust the line width as needed
    state.ctx.stroke();


}


export function draw_rect_st(state, start_x, start_y, width, height, color) {
    state.ctx.beginPath();
    state.ctx.rect(start_x, start_y, width, height);
    state.ctx.fillStyle = color
    state.ctx.fill();
 
}







// Check if the click is inside the button
export function is_clicked(click_x, click_y,box) {
    return click_x >= box.x && click_x <= box.x + box.w && click_y >= box.y && click_y <= box.y + box.h;
}

export function measure_text(state,text) {
    return state.ctx.measureText(text)

}
//export function click_map(objects, 



//audio

  export function playSound(file) {
        const audio = new Audio(file);
        audio.play();
    }

export function gen_circle(x,y,r,color,fill=true,line_width = 3) {
    return {x:x,y:y,r:r,color:color,line_width:line_width,fill}
}

export function draw_circle_st(st,center_x,center_y,radius,color,line_width = 3) {



    
      //var radius = 5;

      st.ctx.beginPath();
      st.ctx.arc(center_x, center_y, radius, 0, 2 * Math.PI, false);
      st.ctx.fillStyle = color//'black';
      st.ctx.fill();    
      st.ctx.lineWidth = line_width;
      st.ctx.strokeStyle = color//'#000000';
      st.ctx.stroke();




}


export function draw_circle_st_b(st,b) {



      st.ctx.beginPath();
    st.ctx.arc(b.x, b.y, b.r, 0, 2 * Math.PI, false);
    if (b.fill) {
      st.ctx.fillStyle = b.color
	st.ctx.fill();
    }
      st.ctx.lineWidth = b.line_width;
      st.ctx.strokeStyle = b.color//'#000000';
      st.ctx.stroke();




}



export function drawRoundedRect_st(st,x, y, width, height, radius) {
    st.ctx.strokeStyle = 'orange'
    st.ctx.lineWidth = 5
        st.ctx.beginPath();
        st.ctx.moveTo(x + radius, y);
        st.ctx.lineTo(x + width - radius, y);
        st.ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
        st.ctx.lineTo(x + width, y + height - radius);
        st.ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
        st.ctx.lineTo(x + radius, y + height);
        st.ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
        st.ctx.lineTo(x, y + radius);
        st.ctx.quadraticCurveTo(x, y, x + radius, y);
        st.ctx.closePath();
        st.ctx.stroke();
    }


export function drawRoundedRect_st_b(st,b, radius) {


    return drawRoundedRect_st(st,b.x,b.y,b.w,b.h,radius)
    

    }
//"rgb(255, 165, 0)";


export function load_image(ctx,canvas,url) {

    // Create a new image object
    const img = new Image();

    img.crossOrigin = "anonymous"; // Request CORS access
    // Set up the image once it loads
    img.onload = function() {
        // Draw the image onto the canvas
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    };


    // Set the source of the image
    img.src = url;
}
