
<template>
  <div class="flex h-screen">
    <!-- Side Navigation -->
    <WerkBankSideNav @changeComponent="changeComponent" class="w-1/4 bg-gray-100 border-r" />



<router-view></router-view>

  </div>
</template>

<style scoped>
/* Additional styles if needed */
</style>


<script>
import WerkBankSideNav from './WerkBankSideNav.vue'



export default {
  components: {
      WerkBankSideNav,

  },
  data() {
    return {
      
    };
  },
  methods: {
    
  }
}
</script>
<style>
  @import url("../assets/output.css");

</style>
