<template>
  <div id="app">

    <div>
      <h1>Mood Board:</h1>
      <input
        type="text"
        v-model="fileName"
        placeholder="Enter a name for the file"
        class="file-name-input"
	/>
      </div>

    <!-- File upload and name input -->
    <div class="upload-section">
      
      <input type="file" @change="onFileChange" accept="image/*" multiple class="file-input" />
    </div>
    
    <!-- Display photos in a grid -->
    <div class="photo-grid">
      <div
        class="photo-item"
        v-for="(photo, index) in photos"
        :key="index"
        @contextmenu="openContextMenu($event, index)"
      >
        <img :src="photo" alt="Mood Board Photo" />
        <div class="photo-name">{{ fileName }}</div>
      </div>
    </div>

    <!-- Custom context menu -->
    <div
      v-if="showContextMenu"
      class="context-menu"
      :style="{ top: `${menuY}px`, left: `${menuX}px` }"
    >
      <ul>
        <li @click="deletePhoto(selectedPhotoIndex)">Delete Photo</li>
        <li @click="editPhoto(selectedPhotoIndex)">Edit Photo</li>
      </ul>
    </div>

    
  </div>

  
</template>


<script>

import { get_token,backend_post, backend_upload,backend_get } from '@/services/cognito-auth';
import { server } from '@/constants.js';

function generateRandomId(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function get_blob(data) {

      const base64String = data.split(',')[1]; // Extract base64 data (remove the "data:image/png;base64," part)
      const byteCharacters = atob(base64String); // Decode the base64 string to binary
      const byteArrays = [];
      
      // Convert the binary data into an array of bytes
      for (let offset = 0; offset < byteCharacters.length; offset++) {
        byteArrays.push(byteCharacters.charCodeAt(offset));
      }
      
      // Create a Blob from the binary data
     return new Blob([new Uint8Array(byteArrays)], { type: "image/png"});

    }

export default {
    name: 'App',
 props: {
	wbId: {
	    type: Number, // or Number, depending on your use case
	    required: true
	}
    },    
  data() {
    return {
	photos: [], // Array to store the uploaded photos
	fileName: '',
	backend_files: null,
	showContextMenu: false,
      menuX: 0,
      menuY: 0,
      selectedPhotoIndex: null,
    };
  },
  methods: {
    // Handle file selection and add images to the photos array
    async onFileChange(event) {
      const files = event.target.files;
      const fileArray = Array.from(files);
      fileArray.forEach(file => {
        const reader = new FileReader();
          reader.onload = async (e) => {
	      let data = e.target.result;
            this.photos.push(e.target.result);

	    const blob = get_blob(data)

	    let mood_id = generateRandomId(12)

	    console.log(mood_id)

	    const formData = new FormData();
	    formData.append('file', blob, mood_id);
	    let ur = await backend_upload(`${server}/upload/mood/${this.wbId}/${mood_id}`,formData)
	      console.log(ur)
	      this.backend_files.files.push(mood_id)


	    let response = await backend_post(`${server}/mood/edit/${this.wbId}`, this.backend_files)
		console.log(response.data);



        };
        reader.readAsDataURL(file);
      });
    },
      openContextMenu(event, index) {
      event.preventDefault(); // Prevent the default context menu
      this.selectedPhotoIndex = index;
      // Capture the mouse coordinates
      this.menuX = event.clientX;
      this.menuY = event.clientY;
      this.showContextMenu = true;
      },
      closeContextMenu() {
      this.showContextMenu = false;
      },
      closeContextMenu() {
      this.showContextMenu = false;
    },      
  },
    async mounted() {
	window.addEventListener("click", this.closeContextMenu);
	console.log("Mounted:",this.wbId)
	
	 if (this.wbId) {
	    let url = `${server}/mood/${this.wbId}`
	    console.log(url)
	    let r  = await backend_get(url)
	     console.log("WTF:",url,r.data)

	     this.fileName = r.data.files.name
	     this.backend_files = r.data.files

	     r.data.files.files.forEach(async (fileName) => {
		 console.log(fileName)
		 let image_b64  = await backend_get(`${server}/mood/image/${this.wbId}/${fileName}`)
//		 console.log(image_b64.data.image)
		 this.photos.push(`data:image/;base64,${image_b64.data.image}`)
		 //
		 //jerschwartz@gmail.com/mood/1/QnqXiPIRzDbh
		 //jerschwartz@gmail.com/mood/1/QnqXiPIRzDbh"
		 })
	     ///api/file/access
	 }


	     }

};
</script>

<style scoped>
/* Basic layout and centering */
#app {
  text-align: center;
  padding: 20px;
  font-family: Arial, sans-serif;
}

h1 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
}

/* Upload Section Styling */
.upload-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.file-name-input {
  padding: 10px;
  font-size: 1rem;
  width: 250px;
  border-radius: 8px;
  border: 1px solid #ddd;
}

.file-input {
  padding: 10px;
  font-size: 1rem;
  cursor: pointer;
  background-color: #f7f7f7;
  border-radius: 8px;
  border: 1px solid #ddd;
  transition: all 0.2s ease;
}

.file-input:hover {
  background-color: #f0f0f0;
}

/* Grid Styling for Photos */
.photo-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
  margin-top: 20px;
}

.photo-item {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.photo-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.photo-item:hover img {
  transform: scale(1.1);
}

.photo-name {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 0.9rem;
  font-weight: bold;
  display: none;
}

.photo-item:hover .photo-name {
  display: block;
}


/* Basic styling for the context menu */
.context-menu {
  position: fixed;
  background-color: #fff;
  border: 1px solid #ccc;
  z-index: 1000;
  width: 150px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.context-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.context-menu li {
  padding: 8px 12px;
  cursor: pointer;
}

.context-menu li:hover {
  background-color: #eee;
}

</style>
