<template>
   <div :class="isOpen ? 'w-64' : 'w-0' " class="bg-white p-6 overflow-hidden transition-width duration-300">
    <!-- Toggle Button -->
    <button @click="isOpen = !isOpen" class="mb-4">
      <span v-if="isOpen">Close</span>
      <span v-else>Open</span>
    </button>
    
    <div v-if="isOpen">
      <h2 class="font-semibold text-xl mb-4 border-b pb-2">Mood</h2>
      <ul>
	        <li class="my-2">
          <router-link class="text-gray-700 hover:text-blue-600 transition-colors" :active-class="activeLinkClass" to="/cards" exact>Cards</router-link>
        </li>

		  <li class="my-2">
          <router-link class="text-gray-700 hover:text-blue-600 transition-colors" :active-class="activeLinkClass" to="/quiz" exact>Quiz</router-link>
        </li>

      </ul>
      <h2 class="font-semibold text-xl mb-4 border-b pb-2"></h2>
      <ul>

	<li>
       
          <router-link class="text-gray-700 hover:text-blue-600 transition-colors" :active-class="activeLinkClass" to="/mood/new" exact>New</router-link>
        </li>
		<li>
       
          <router-link class="text-gray-700 hover:text-blue-600 transition-colors" :active-class="activeLinkClass" to="/mood/list" exact>List</router-link>
        </li>	

        <!-- Add more general links here -->
      </ul>
    </div>
   </div>

   
</template>

<script>
import axios from 'axios';
import { get_token } from '@/services/cognito-auth';
import { mapState } from 'vuex';

export default {
  data() {
    return {
	isOpen: true,
	activeLinkClass: "font-bold border-l-4 border-blue-600 pl-2"
    };
  },
    methods: {
	
   
	
  },    
    async mounted() {

	console.log("Mounted")

	console.log("S2:",this.$store.state.featureFlags)
	
  
    },
    computed: {
	featureFlags() {
	    return this.$store.state.featureFlags
	    }
  }


}
</script>
