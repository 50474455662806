<template>
  <div>
    <h1>Mood Name:</h1>
    <input v-model="inputValue" type="text" class="border p-2 rounded" placeholder="Enter text here">
    <button @click="handleButtonClick" class="bg-blue-500 text-white p-2 rounded ml-2">Submit</button>
  </div>
</template>

<script>
import axios from 'axios';
import { get_token,backend_get,backend_post } from '@/services/cognito-auth';
import { server } from '@/constants.js';
  
export default {
  data() {
    return {
      inputValue: ''
    };
  },
  methods: {
    async handleButtonClick() {
      console.log("Button clicked with input value:", this.inputValue);
	// Handle the button click logic here

	//TODO org

	let new_mood = {files:[],name:this.inputValue}
	
	let r = await backend_post(`${server}/mood/add`, new_mood)

	this.$router.push('/mood/list');

    }
  }
}
</script>

<style scoped>
</style>
