import BiwaScheme from 'biwascheme';


import {daysBetween2,getCurrentDateYMD} from '@/services/zeit';
import {init,draw_circle_st} from '@/services/dermotor';
import {drawCell} from '@/services/zelle';



BiwaScheme.define_libfunc("test", 2, 2, function(ar){
  BiwaScheme.assert_integer(ar[0]);
  BiwaScheme.assert_integer(ar[1]);
  return ar[0] + ar[1];
});


BiwaScheme.define_libfunc("date-diff", 2, 2, function(ar){
 // BiwaScheme.assert_integer(ar[0]);
    // BiwaScheme.assert_integer(ar[1]);
    return daysBetween2(ar[0],ar[1])

});

BiwaScheme.define_libfunc("date-today", 0, 0, function(ar){
 // BiwaScheme.assert_integer(ar[0]);
    // BiwaScheme.assert_integer(ar[1]);
    return getCurrentDateYMD()
//    return daysBetween2(ar[0],ar[1])

});




//canvas

BiwaScheme.define_libfunc("date-today", 0, 0, function(ar){
 // BiwaScheme.assert_integer(ar[0]);
    // BiwaScheme.assert_integer(ar[1]);
    return getCurrentDateYMD()
//    return daysBetween2(ar[0],ar[1])

});

BiwaScheme.define_libfunc("draw-circle", 0, 0, function(ar){
 // BiwaScheme.assert_integer(ar[0]);
    // BiwaScheme.assert_integer(ar[1]);
   let st = init('bottom_canvas')

    draw_circle_st(st,50,50,5,'red')
    return true
//    return daysBetween2(ar[0],ar[1])

});


BiwaScheme.define_libfunc("draw-cell", 0, 0, function(ar){
 // BiwaScheme.assert_integer(ar[0]);
    // BiwaScheme.assert_integer(ar[1]);
   let st = init('bottom_canvas')

    drawCell(st.ctx,st.canvas.width / 2, st.canvas.height / 2, 100, 60, 15, 100);
    return true
//    return daysBetween2(ar[0],ar[1])

});



export function scheme_init() {
    console.log
}
