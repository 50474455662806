<template>
  <div class="container">
    <!-- Left pane: toolbar + textarea -->
    <div class="left-pane">
      <div class="toolbar">
        <!-- New file name text field -->
        <input
          type="text"
          v-model="fileName"
          placeholder="Enter file name"
          class="file-name-input"
        />

        <button @click="runCode" >Run</button>
        <button @click="saveCode">Save</button>
        <button @click="clearCode">Clear</button>
      </div> 
      <textarea id="editor" placeholder="Write something here..." v-model="editorValue" @input="updateHighlight" class="code-input"></textarea>
          <!--<pre class="code-display" v-html="highlightedCode"></pre> -->
    </div>

    <!-- Right pane: top and bottom panels -->
    <div class="right-side">
      <div id="top-pane">
        <h2>Top Pane</h2>
        <p>{{ topPaneContent }}</p>
      </div>
      <div id="bottom-pane">
    <canvas id="bottom_canvas"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import BiwaScheme from 'biwascheme';
import CodeMirror from 'vue-codemirror6';
import {scheme_init} from '@/services/scheme-helpers';

import { get_token,backend_post, backend_upload,backend_get } from '@/services/cognito-auth';
import { server } from '@/constants.js';



function run_scheme(code,fn) {
var onError = function(e){ console.error(e); }
var biwa = new BiwaScheme.Interpreter(onError)
    biwa.evaluate(code, fn)
}

//const topPaneContent = ref('This could be a preview, logs, or anything else.')
/*
// Reactive state for the editor text
const editorValue = ref('')
// Some placeholders for demonstration

const bottomPaneContent = ref('Additional info, console, or results can go here.')

// "Run" button handler
async function runCode() {
    topPaneContent.value = `Running code...\n\n${editorValue.value}`
    run_scheme(editorValue.value,topPaneContent)
    let url = `${server_v2}/test`
    //let r  = await backend_get(url)
    //phconsole.log("WTF:",url,r)
}

// "Save" button handler
const saveCode = () => {
  alert('Code saved (simulated)!')
  console.log('Saved code:', editorValue.value)
}

// "Clear" button handler
const clearCode = () => {
  editorValue.value = ''
}
let r =  await backend_get(`${server}/ts/get/${this.tsId}`)
*/

export default {
 

    props: {
	wbId: {
	    type: Number, // or Number, depending on your use case
	    required: true
	}
    },
    components: {

    },
    data() {
	
	return {
	    editorValue: "",
	    topPaneContent: "",
	    fileName: "",
	    highlightedCode: '', 
    }
},
    methods: {
	updateHighlight() {

// Basic example of syntax highlighting using regex.
      // Note: For production, you might need a more robust tokenizer.
      let highlighted = this.editorValue;

      // Escape HTML characters first
      highlighted = highlighted.replace(/&/g, '&amp;')
                                 .replace(/</g, '&lt;')
                                 .replace(/>/g, '&gt;');

      // Highlight comments (everything after a semicolon until end of line)
      highlighted = highlighted.replace(/(;[^\n]*)/g, '<span class="comment">$1</span>');

      // Highlight strings (simple version; not handling escapes)
      highlighted = highlighted.replace(/(".*?")/g, '<span class="string">$1</span>');

      // Highlight keywords (add more Scheme keywords as needed)
      highlighted = highlighted.replace(/\b(define|lambda|if|let|begin)\b/g, '<span class="keyword">$1</span>');

      // Highlight numbers
      highlighted = highlighted.replace(/\b(\d+(\.\d+)?)\b/g, '<span class="number">$1</span>');

      // Wrap parentheses (optional, if you want to style them)
      highlighted = highlighted.replace(/([\(\)])/g, '<span class="paren">$1</span>');

      this.highlightedCode = highlighted;	    
	},
	
	runCode() {
	    console.log(this.editorValue,this.wbId)
	    //this.topPaneContent = `Running code...\n\n${this.editorValue}`
	    run_scheme(this.editorValue, (result) => {
		//alert(result);  //=> 3
		console.log("RESULT3:",result)
		
		this.topPaneContent = `Running code...\n\n${result}`
	    })
	    
	},
	async saveCode() {

	    if (this.wbId) {
	    console.log('Saved code:', this.editorValue)
		let code = {code:this.editorValue,name:this.fileName}
	    let response = await backend_post(`${server}/werkbank/edit/${this.wbId}`, code)
		console.log(response.data);
		

	    }
	    else {

		let code = {code:this.editorValue,name:this.fileName}
		console.log("NEW:",code)

		let response = await backend_post(`${server}/werkbank/add`, code)
		console.log(response.data);
		this.$router.push(`/werkbank/edit/${response.data.id}`)

	    }
	    
	},
	userClickArrowUp( code ) {
	    
	    if (event.key == 'r' && event.ctrlKey)  {
		console.log("KEY")
		this.runCode()
		return
	    }
	    if (event.key == 's' && event.ctrlKey)  {
		console.log("Save")
		this.saveCode()
	    }
	    
	},
    },
    
     async mounted() {
	 console.log("Mounted:",this.wbId)
	 if (this.wbId) {
	    let url = `${server}/werkbank/${this.wbId}`
	    console.log(url)
	    let r  = await backend_get(url)
	     console.log("WTF:",url,r.data)
	     let code = r.data.code.code
	     
	 console.log(code)
	     this.editorValue = code
	     this.fileName = r.data.code.name
	     this.updateHighlight()

     }
	 scheme_init()

	 document.addEventListener('keyup', this.userClickArrowUp, true);
     }
}
</script>

<style scoped>


/* Reset & basic styles */
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  font-family: sans-serif;
}

/* Root container: 2 columns (left editor, right vertical split) */
.container {
  display: flex;
  height: 100vh;
  width: 100vw;
}

/* Left pane: a toolbar + textarea */
.left-pane {
  flex: 1;                 /* Takes about half the width */
  max-width: 50vw;         /* Adjust as needed */
  border-right: 1px solid #ccc;
  display: flex;
  flex-direction: column;  /* Stack toolbar above the textarea */
}

/* Toolbar at the top of the left pane */
.toolbar {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-bottom: 1px solid #ccc;
  background-color: #f5f5f5;
}

.toolbar button {
  margin-right: 0.5rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

/* The textarea fills remaining space below the toolbar */

#editor {
  flex: 1;
  padding: 1rem;
  border: none;
  outline: none;
  font-family: monospace;
  font-size: 1rem;
  resize: none; 
}


/*
#editor {
  position: relative;
  font-family: monospace;
}
*/

/* Position the textarea exactly under the highlighted pre */
#code-input {
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
  color: transparent;
  caret-color: black; /* so the cursor is still visible */
  border: 1px solid #ccc;
  width: 100%;
  height: 100%;
  resize: none;
}

/* This element displays the highlighted code */
#code-display {
  pointer-events: none; /* so clicks fall through to the textarea */
  white-space: pre-wrap;
}

/* Example styles for syntax elements */
#keyword { color: blue; }
.comment { color: green; }
#string  { color: brown; }
.number  { color: red; }
#paren   { color: #888; }

/* Right side: split into top & bottom */
.right-side {
  display: flex;
  flex-direction: column;
  flex: 1;
}

#top-pane,
#bottom-pane {
  flex: 1;
  padding: 1rem;
  overflow: auto;
}

#top-pane {
  border-bottom: 1px solid #ccc;
}
</style>
